import React from "react"
import { Layout, SEO } from '@/components/index'
import { Header, Body } from '@/components/NebulaApp'
import { nebulaVideo } from '@/images/nebula/index'
const App = () => {
  return (
    <>
      <Layout
        wholebg={true}
        footerBackground='linear-gradient(180deg, rgba(17, 17, 17, 0.5) 0%, rgba(17, 17, 17, 0.5) 100%, rgba(17, 17, 17, 0.6) 100%)'
        contentBackground={{ type: "video", content: nebulaVideo }}
      >
        <SEO page="Nebula" />
        <Header />
        <Body />
      </Layout>
    </>

  )
}

export default App
