import React from 'react'
import style from './index.module.less'
import { Arrow, ShiXinBaiBtn_updown } from '@/components/index'
import useNavigate from '@/hooks/useNavigate'
import { useIntl } from "react-intl"

const Header = () => {
    const intl = useIntl()
    return (
        <div className={style.header} >
            <h1 className={style.title}>Nebula</h1>
            <div className={style.dec} dangerouslySetInnerHTML={{ __html: `${intl.formatMessage({ id: `nebula_intro`, defaultMessage: "Nebula is XREAL's proprietary software that provides an operating environment for AR apps built with the XREAL SDK.<br/>It is also a testbed for breeding new ideas and innovation." })}` }}></div>
            <div className={style.tip} dangerouslySetInnerHTML={{ __html: `${intl.formatMessage({ id: 'nebula_intro_tip', defaultMessage: '*Nebula is required for <a href="/experience/?virtual-desktop">Virtual Desktop</a> and <a href="/experience/?ar-space">AR Space experience</a>.' })}` }} >
            </div>
            <div className={style.download}>{intl.formatMessage({ id: "download_text", defaultMessage: "Download" })}</div>
            <Arrow />
        </div>
    )
}

export default Header