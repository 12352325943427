import React from 'react'
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/swiper.min.css";
import { Autoplay } from "swiper";
import "swiper/modules/pagination/pagination.min.css";
import "swiper/modules/navigation/navigation.min.css";
import "./index.css";
//reverseDirection:true  swpier向右滚动
const SwiperTrack = ({ children, reverseDirection, options }) => {
    return (
        <Swiper
            allowTouchMove={false}
            slidesPerView='auto'
            mousewheel={false}
            modules={[Autoplay]}
            className="newsMediaSwiper"
            loop={true}
            initialSlide={2}
            speed={options?.speed || 5000}
            autoplay={{
                disableOnInteraction: false,
                reverseDirection: reverseDirection,
                delay: 1
            }}
        >
            {children}
        </Swiper>
    )
}

export default SwiperTrack