
import android from './android.svg'
import mac from './mac.svg'
import mac_download from '@/images/icons/mac_download.svg'
import windowIcon from './window.svg'
const nebulaVideo = `${process.env.resourceUrl}video/nebula.mp4`

export {
    android,
    mac,
    windowIcon,
    nebulaVideo,
    mac_download
}

