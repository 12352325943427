import React, { useState, useEffect } from "react";
import style from './index.module.less'
import useNavigate from '@/hooks/useNavigate'
import { TouMingBaiBtn_updown, SwiperTrack, ShiXinBaiBtn_updown } from '@/components/index'
import { SwiperSlide } from "swiper/react";
import {
    android,
    mac,
    windowIcon,
} from '@/images/nebula/index'
import { nebulaIcon, nebulaMacIcon, googlePlay } from '@/images/experience/index'
import downloadfiles from "@/utils/downloadfile"
import { useIntl } from "react-intl"
import useGa from "@/hooks/useGa"
import getCountry from '@/utils/getCountry.js'
import { getAppShow, getNrealapp } from '@/services/request'


const Body = () => {
    const intl = useIntl()
    const [imgList, setImgList] = useState([''])
    const region = getCountry()
    const downloadList = [
        {
            title: "Nebula for Android",
            desc: "Nebula for Nreal Light & Nreal Air users: Download now from the Google Play Store and light up your AR journey today!",
            link: region == 'th' ? '' : "https://play.google.com/store/apps/details?id=ai.nreal.nebula.universal",
            icon: android,
            cate: 'nebula_for_andriod'
        },
        {
            title: "Nebula for Mac",
            desc: "Nebula for Nreal Light & Nreal Air users: Download now from the Google Play Store and light up your AR journey today!",
            link: { currentVersionCode: 0, currentServiceModel: 1, currentPackageName: 'ai.nreal.nebula.mac' },
            icon: mac,
            cate: 'nebula_for_mac'
        },
        {
            title: "Nebula for Windows",
            desc: "Nebula for Nreal Light & Nreal Air users: Download now from the Google Play Store and light up your AR journey today!",
            link: '',
            icon: windowIcon,
            cate: 'nebula_for_windows'
        }
    ]
    useEffect(() => {
        getAppShow()
            .then(d => {
                let arr = []
                d?.data?.data.map(item => {
                    arr.push(item.poster)
                })

                setImgList(arr)
            })

    }, [])
    const androidDownload = (link) => {
        window.open(link)
    }
    const macDownload = (link) => {
        getNrealapp(link).then((res) => {
            downloadfiles(res.data.data.latestVersion.packageUrl)
        })
    }
    return (
        <div className={style.body} id='download'>
            <div className={style.download}>
                {downloadList.map((item, index) => (
                    <div className={style.downloadItem} key={`nebulaDownload${index}`}>
                        <h2 className={style.downloadItemTitle}>{item.title}</h2>
                        <img src={index == 0 ? nebulaIcon : nebulaMacIcon} className={style.downloadItemIcon} />
                        <div className={style.downloadItemDesc}>
                            {/* {item.desc} */}
                        </div>
                        {item.link ? <TouMingBaiBtn_updown styles={{ border: `2px solid #FFFFFF` }} onClick={() => {
                            useGa('nebula', item.cate, 'Click')
                            index == 0 ? androidDownload(item.link) : macDownload(item.link)
                        }}>

                            <div className={style.downloadItemBtn}>
                                {index == 0 ? <img src={googlePlay} className={style.googleImg} /> : <><img src={item.icon} />
                                    {intl.formatMessage({ id: "download_text", defaultMessage: "Download" })}</>}

                            </div>
                        </TouMingBaiBtn_updown> : <TouMingBaiBtn_updown styles={{ border: `2px solid rgba(255,255,255,0.4)`, cursor: "auto" }} >

                            <div className={style.downloadItemBtn} style={{ color: 'rgba(255,255,255,0.4)' }}>
                                {intl.formatMessage({ id: "coming_sone_text", defaultMessage: "Coming soon" })}
                            </div>
                        </TouMingBaiBtn_updown>}
                    </div>
                ))}
            </div>
            <div className={style.arlab}>
                <h2 className={style.arlabTitle}>AR Lab</h2>
                <div className={style.arlabDesc}>{intl.formatMessage({ id: "arLab_intro", defaultMessage: "We have received quite some interesting AR apps from developers and put them all here for your pleasure. Hats off to all the innovative pioneers." })}</div>
                <SwiperTrack reverseDirection={true}>
                    {imgList.map((item, index) => (
                        <SwiperSlide>
                            <div className={style.swiperItem} key={`arlabImg${index}`} style={{ backgroundImage: `url(${item})` }}></div>
                        </SwiperSlide>
                    ))}


                </SwiperTrack>
                <SwiperTrack >
                    {imgList.map((item, index) => (
                        <SwiperSlide>
                            <div className={style.swiperItem} key={`arlabImg${index}`} style={{ backgroundImage: `url(${item})` }}></div>
                        </SwiperSlide>
                    ))}


                </SwiperTrack>
                <ShiXinBaiBtn_updown styles={{ margin: "60px auto 0" }} onClick={() => {
                    useGa('nebula', 'nebula_ARLab', 'Click')
                    useNavigate('/arlab')
                }}>
                    <div className={style.arlabBtn}>{intl.formatMessage({ id: "nebula_goArlab", defaultMessage: "Go To AR Lab" })}</div>
                </ShiXinBaiBtn_updown>
            </div>
        </div>)
}

export default Body