import React from 'react'
import  gaming_arrow   from '@/images/icons/arrow_animate.svg'
import style from './index.module.less'
const Arrow = ()=>{

    return(
        <div className={style.arrow}>
        <img src={gaming_arrow} className={style.arrow1} />
        <img src={gaming_arrow} className={style.arrow2}/>
        <img src={gaming_arrow} className={style.arrow3}/>
    </div>

    )
}

export default Arrow